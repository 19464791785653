import { ClockIcon, CurrencyPoundIcon, XMarkIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { Booking } from '__generated__/graphql';
import Avatar from 'components/Avatar';
import { format } from 'date-fns';

interface BookingProps {
  booking: Booking;
  onClose: () => void;
}

const BookingModal = ({ booking, onClose }: BookingProps) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={onClose}>
        <div className="relative w-auto mx-auto max-w-3xl">
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-7 pb-10 pt-5 md:max-h-[90vh] md:min-w-[380px]"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex flex-row justify-end">
              <button className="text-darkBlue text-p24 font-bold" onClick={onClose}>
                <XMarkIcon className="w-10 h-10" />
              </button>
            </div>
            <div className="flex flex-row items-center justify-center mt-5">
              <div className="w-[64px] h-[64px] lg:w-[100px] lg:h-[100px] rounded-full p-0.5 border-2 border-secondary shrink-0">
                <Avatar image={booking.partner?.image?.url ?? ''} />
              </div>
            </div>
            <div className="text-p18 sm:text-p20 md:text-p22 lg:text-p24 xl:text-p28 text-center mt-3 text-darkBlue">
              <span className="font-bold">{booking.partner?.firstName}</span> from {booking.partner?.businessName}
            </div>
            {!booking.amITheSupportedPerson && booking.supportedPerson && (
              <div className="text-p16 md:text-p20 lg:text-p22 xl:text-p24 text-center text-darkBlue">
                For {booking.supportedPerson?.firstName} {booking.supportedPerson?.surname}
              </div>
            )}
            <div className="text-p16 md:text-p18 lg:text-p20 text-darkBlue mt-2">{booking.product?.name}</div>
            <div className="flex flex-row items-center mt-4 text-darkBlue">
              <CalendarDaysIcon className="w-6 h-6 text-darkBlue" />
              <div className="text-p16 lg:text-p18 ml-2">{format(booking.startDateTime, 'do MMMM yyyy')}</div>
            </div>
            <div className="flex flex-row items-center mt-4 text-darkBlue">
              <ClockIcon className="w-6 h-6 text-darkBlue" />
              <div className="text-p16 lg:text-p18 ml-2">
                {format(booking.startDateTime, 'h:mm aaa')} - {format(booking.endDateTime, 'h:mm aaa')}
              </div>
            </div>
            <div className="flex flex-row items-center mt-4 text-darkBlue">
              <CurrencyPoundIcon className="w-6 h-6 " />
              <div className="text-p16 lg:text-p18 ml-2">£{booking?.partnerPrice && booking?.partnerPrice?.bookingPrice?.toFixed(2).replace(/\.00$/, '')}</div>
            </div>
            <div className="flex flex-row items-start mt-4 text-darkBlue">
              <MapPinIcon className="w-6 h-6 text-darkBlue" />
              <div className="text-p16 lg:text-p18 ml-2">
                <div>{booking.address?.addressLine1}</div>
                <div>{booking.address?.addressLine2}</div>
                <div>{booking.address?.city}</div>
                <div>{booking.address?.region}</div>
                <div>{booking.address?.postcode}</div>
                <div>{booking.address?.addressNotes}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
};

export default BookingModal;
