import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Partner } from '__generated__/graphql';
import SearchHeader from 'components/SearchHeader';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { BookStoryblok } from 'types/components-sb';
import { GET_POSTCODE_LOOKUP } from 'api';
import { displayToast } from 'utils/utils';
import { ToastStatus } from 'types/enum';
import { useCreateBooking } from 'api/hooks/useCreateBooking';
import Loading from 'components/Loading';
import ContactBox from 'components/ContactBox';
import { Auth, Hub } from 'aws-amplify';
import SignupForm from 'components/SignupForm';
import Login from 'components/SignIn';
// import { useGetBookings } from 'api/hooks/useGetBookings';

interface BookProps {
  blok: BookStoryblok;
}

const Book = ({ blok }: BookProps) => {
  const { createBooking, mutationCreateBooking } = useCreateBooking();
  // const { bookings, loadingBookings, refetch } = useGetBookings(localStorage.getItem('userId') ?? '', !localStorage.getItem('userId'));
  const partner: Partner | undefined = localStorage.getItem('partner') ? JSON.parse(localStorage.getItem('partner') ?? '') : undefined;
  const categoryId = localStorage.getItem('categoryId');
  const searchPostcode = localStorage.getItem('postcode');
  const category = localStorage.getItem('category');
  const productId = localStorage.getItem('productId');
  const startTime = localStorage.getItem('availabilitySlotStartDateTime');
  const endTime = localStorage.getItem('availabilitySlotEndDateTime');
  const availabilitySlotStartDateTime = parseInt(localStorage.getItem('availabilitySlotStartDateTime') ?? '', 10);
  const availabilitySlotEndDateTime = parseInt(localStorage.getItem('availabilitySlotEndDateTime') ?? '', 10);
  const questions = localStorage.getItem('questions') ? JSON.parse(localStorage.getItem('questions') ?? '') : undefined;
  const productName = localStorage.getItem('productName');
  const [showLogin, setShowLogin] = useState(false);
  const [isServiceForSomeoneElse, setIsServiceForSomeoneElse] = useState<boolean>();
  const [customerFirstName, setCustomerFirstName] = useState<string>('');
  const [customerLastName, setCustomerLastName] = useState<string>('');
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [addressNotes, setAddressNotes] = useState<string>('');
  const [otherInformations, setOtherInformations] = useState<string>('');
  const [supportedPersonFirstName, setSupportedPersonFirstName] = useState<string>('');
  const [supportedPersonLastName, setSupportedPersonLastName] = useState<string>('');
  const [supportedPersonRelationship, setSupportedPersonRelationship] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [postcode, setPostcode] = useState<string>(searchPostcode ?? '');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [tAndCChecked, setTAndCChecked] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  // const [isFirstBooking, setIsFirstBooking] = useState(true);
  const address = JSON.parse(localStorage.getItem('address') ?? '');
  const supportedPerson = localStorage.getItem('supportedPerson') ? JSON.parse(localStorage.getItem('supportedPerson') ?? '') : undefined;

  useEffect(() => {
    if (!categoryId || !category || !productId || !partner || !startTime || !endTime || !searchPostcode) {
      window.location.href = '/';
    }
  }, [category, categoryId, endTime, partner, productId, startTime, searchPostcode]);

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken();
        const jwt = accessToken.getJwtToken();
        localStorage.setItem('token', jwt);
        if (user && user.username) {
          // refetch(user.username);
          setIsLoggedIn(true);
        }
      } catch {
        setIsLoggedIn(false);
        setShowLogin(true);
        // setIsFirstBooking(true);
      }
    };
    Hub.listen('auth', checkUserAuth);
    checkUserAuth();
    return () => Hub.remove('auth', checkUserAuth);
  }, []);

  // useEffect(() => {
  //   if (bookings && bookings.GetBookings && bookings.GetBookings.length > 0) {
  //     setIsFirstBooking(false);
  //   }
  // }, [bookings]);

  const isBookingValid = async () => {
    if (
      isServiceForSomeoneElse === undefined ||
      customerFirstName === '' ||
      customerLastName === '' ||
      customerEmail === '' ||
      customerPhone === '' ||
      addressLine1 === '' ||
      city === '' ||
      region === '' ||
      postcode === '' ||
      (isServiceForSomeoneElse && (supportedPersonFirstName === '' || supportedPersonLastName === '' || supportedPersonRelationship === ''))
    ) {
      displayToast(ToastStatus.ERROR, 'Please fill all the required fields.');
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(customerEmail)) {
      displayToast(ToastStatus.ERROR, 'Please enter a valid email address.');
      return false;
    }
    const res = await GET_POSTCODE_LOOKUP(postcode);
    if (res.status !== 200) {
      displayToast(ToastStatus.ERROR, res.error);
      return false;
    }

    if (!/[A-Z]/.test(password) || !/\d/.test(password) || password.length < 8) {
      displayToast(ToastStatus.ERROR, 'The password must have a capital letter, a number and be at least 8 characters.');
      return false;
    }
    if (password !== passwordConfirmation) {
      displayToast(ToastStatus.ERROR, 'The confirmation password is different than the password.');
      return false;
    }
    if (!tAndCChecked) {
      displayToast(ToastStatus.ERROR, 'Please agree to the terms & conditions.');
      return false;
    }
    return true;
  };

  const onBookClick = async () => {
    if (!isLoggedIn) {
      const isValid = await isBookingValid();
      if (!isValid) {
        return;
      }
    } else {
      if (!tAndCChecked) {
        displayToast(ToastStatus.ERROR, 'Please agree to the terms & conditions.');
        return false;
      }
    }
    const partnerId = partner?.id;
    const partnerPriceId = partner?.prices && partner?.prices[0]?.id;
    let input = {};
    if (isLoggedIn) {
      input =
        address.addressLine1 !== ''
          ? {
              customerId: localStorage.getItem('userId'),
              partnerId,
              productId: localStorage.getItem('productId'),
              partnerPriceId,
              startDateTime: availabilitySlotStartDateTime,
              endDateTime: availabilitySlotEndDateTime,
              isRecurring: false,
              preQualifyingQuestions: questions,
              amITheSupportedPerson: !supportedPerson,
              supportedPersonId: supportedPerson ? supportedPerson.id : undefined,
              address: {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                city: address.city,
                region: address.region,
                postcode: address.postcode,
                addressNotes: address.addressNotes,
              },
            }
          : {
              customerId: localStorage.getItem('userId'),
              partnerId,
              productId: localStorage.getItem('productId'),
              partnerPriceId,
              startDateTime: availabilitySlotStartDateTime,
              endDateTime: availabilitySlotEndDateTime,
              isRecurring: false,
              preQualifyingQuestions: questions,
              amITheSupportedPerson: !isServiceForSomeoneElse,
              supportedPerson: isServiceForSomeoneElse
                ? {
                    firstName: supportedPersonFirstName,
                    surname: supportedPersonLastName,
                    relationship: supportedPersonRelationship,
                    address: {
                      addressLine1,
                      addressLine2,
                      city,
                      region,
                      postcode,
                      addressNotes,
                    },
                  }
                : undefined,
              address: {
                addressLine1,
                addressLine2,
                city,
                region,
                postcode,
                addressNotes,
              },
            };
    } else if (isServiceForSomeoneElse) {
      input = {
        supportedPerson: {
          firstName: supportedPersonFirstName,
          surname: supportedPersonLastName,
          relationship: supportedPersonRelationship,
          address: {
            addressLine1,
            addressLine2,
            city,
            region,
            postcode,
            addressNotes,
          },
        },
        customer: {
          firstName: customerFirstName,
          surname: customerLastName,
          email: customerEmail,
          password,
          phoneNumber: customerPhone,
        },
        partnerId,
        productId: localStorage.getItem('productId'),
        partnerPriceId,
        startDateTime: availabilitySlotStartDateTime,
        endDateTime: availabilitySlotEndDateTime,
        isRecurring: false,
        preQualifyingQuestions: questions,
        amITheSupportedPerson: false,
        address: {
          addressLine1,
          addressLine2,
          city,
          region,
          postcode,
          addressNotes,
        },
      };
    } else {
      input = {
        customer: {
          firstName: customerFirstName,
          surname: customerLastName,
          email: customerEmail,
          password,
          phoneNumber: customerPhone,
          address: {
            addressLine1,
            addressLine2,
            city,
            region,
            postcode,
            addressNotes,
          },
          otherInformations,
        },
        partnerId,
        productId: localStorage.getItem('productId'),
        partnerPriceId,
        startDateTime: availabilitySlotStartDateTime,
        endDateTime: availabilitySlotEndDateTime,
        isRecurring: false,
        preQualifyingQuestions: questions,
        amITheSupportedPerson: true,
        address: {
          addressLine1,
          addressLine2,
          city,
          region,
          postcode,
          addressNotes,
        },
      };
    }
    const res = await createBooking({
      variables: {
        input,
      },
    });
    if (res.errors) {
      displayToast(ToastStatus.ERROR, res.errors[0].message);
      return;
    }
    if (partner && partner?.prices) {
      localStorage.setItem(
        'bookingConfirmation',
        JSON.stringify({
          partnerAvatar: partner?.image?.url,
          partnerFirstName: partner?.firstName,
          partnerBusinessName: partner?.businessName,
          price: partner?.prices[0]?.bookingPrice?.toFixed(2).replace(/\.00$/, ''),
          startTime: availabilitySlotStartDateTime,
        }),
      );
    }
    window.location.href = '/booking-confirmation';
  };

  if (mutationCreateBooking.loading) {
    return <Loading />;
  }

  return (
    <div>
      <SearchHeader text="Your booking with" boldText={partner?.firstName} avatar={partner?.image?.url ?? ''} />
      <div className="p-4 md:px-[5%] lg:px-[10%] xl:px-[15%] mt-10">
        <div className="text-darkBlue text-p28 font-bold">{blok.title}</div>
        {isLoggedIn && <div className="text-darkBlue text-p20 font-semibold mt-2">Please check the details below are correct</div>}
        <div className="bg-primary text-white p-7 mt-4 lg:mt-8 rounded-xl">
          <div className="text-p18 md:text-p24 lg:text-p28">
            Service: <span className="font-bold">{productName}</span>
          </div>
          <div className="text-p18 md:text-p24 lg:text-p28 mt-2">
            Date and time:{' '}
            {startTime && endTime && (
              <span className="font-bold">
                {format(availabilitySlotStartDateTime, 'dd/MM/yyyy hh:mm aaa')} - {format(availabilitySlotEndDateTime, 'hh:mm aaa')}
              </span>
            )}
          </div>
          <div className="text-p18 md:text-p24 lg:text-p28 mt-2">
            Cost for this service: <span className="font-bold">£{partner?.prices && partner?.prices[0]?.bookingPrice?.toFixed(2).replace(/\.00$/, '')}</span>
          </div>
          <div className="text-p16 md:text-p24 mt-4">{blok.costText}</div>
        </div>
        {isLoggedIn && address.addressLine1 !== '' ? (
          <div className="mt-10">
            <div className="text-p22 md:text-p24 font-semibold mb-3 text-darkBlue">This booking is for:</div>
            <div className="bg-bgBlue p-7 rounded-xl flex flex-col sm:flex-row sm:items-center justify-between gap-4 mt-5">
              <div className="text-darkBlue text-p18 md:text-p20">
                <div className="text-p22 md:text-p24 font-semibold mb-3">
                  {supportedPerson ? `${supportedPerson.firstName} ${supportedPerson.surname}` : 'Me'}
                </div>
                <div data-cy="book-address-line-1">{address.addressLine1}</div>
                <div data-cy="book-address-line-2">{address.addressLine2}</div>
                <div data-cy="book-address-city">{address.city}</div>
                <div data-cy="book-address-region">{address.region}</div>
                <div data-cy="book-address-postcode">{address.postcode}</div>
                <div data-cy="book-address-notes">{address.addressNotes}</div>
              </div>
            </div>
          </div>
        ) : (
          <SignupForm
            blok={blok}
            isServiceForSomeoneElse={isServiceForSomeoneElse}
            supportedPersonFirstName={supportedPersonFirstName}
            supportedPersonLastName={supportedPersonLastName}
            customerFirstName={customerFirstName}
            customerLastName={customerLastName}
            customerEmail={customerEmail}
            customerPhone={customerPhone}
            addressNotes={addressNotes}
            otherInformations={otherInformations}
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            region={region}
            postcode={postcode}
            password={password}
            passwordConfirmation={passwordConfirmation}
            setIsServiceForSomeoneElse={setIsServiceForSomeoneElse}
            setSupportedPersonFirstName={setSupportedPersonFirstName}
            setSupportedPersonLastName={setSupportedPersonLastName}
            setSupportedPersonRelationship={setSupportedPersonRelationship}
            setCustomerFirstName={setCustomerFirstName}
            setCustomerLastName={setCustomerLastName}
            setCustomerEmail={setCustomerEmail}
            setCustomerPhone={setCustomerPhone}
            setAddressNotes={setAddressNotes}
            setOtherInformations={setOtherInformations}
            setAddressLine1={setAddressLine1}
            setAddressLine2={setAddressLine2}
            setCity={setCity}
            setRegion={setRegion}
            setPostcode={setPostcode}
            setPassword={setPassword}
            setPasswordConfirmation={setPasswordConfirmation}
            isSignedInNewAddress={isLoggedIn}
            isPostcodeDisabled={true}
          />
        )}
        <div className="flex flex-row justify-end mt-10 lg:mt-[56px]">
          <div className="bg-bgBlue p-5 rounded-xl w-full lg:w-[60%] text-darkBlue ">
            <div className="text-p20 lg:pl-[34px] mb-5">{blok.termsAndConditionsDescription}</div>
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                className="w-6 h-6 accent-darkBlue cursor-pointer shrink-0"
                checked={tAndCChecked}
                onChange={() => setTAndCChecked(!tAndCChecked)}
                data-cy="t-and-c-checkbox"
              />
              <div className="text-p20 pl-2.5">
                I agree to the{' '}
                <a href={blok.termsAndConditionsFile?.filename} target="_blank" rel="noreferrer" className="font-bold">
                  terms & conditions
                </a>{' '}
                of service
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-10">
          <button onClick={onBookClick} className="bg-secondary px-6 py-3.5 rounded-xl w-full sm:w-fit cursor-pointer" data-cy="book-now-button">
            <div className="flex items-center justify-center text-darkBlue">
              <div className="text-p20 md:text-p24 font-bold">Book now</div>
              <ArrowRightIcon className="h-7 w-7 ml-3 mt-1" />
            </div>
          </button>
        </div>
        <ContactBox />
      </div>
      {showLogin && <Login onClose={() => setShowLogin(false)} fromBooking />}
    </div>
  );
};

export default Book;
